// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "w_fM d_fM d_bw d_bD";
export var navbarDividedRight = "w_fN d_fN d_bw";
export var menuLeft = "w_sN d_fK d_bw d_ds d_bL";
export var menuRight = "w_sP d_fK d_bw d_ds d_bL";
export var menuCenter = "w_sQ d_fL d_fK d_bw d_ds d_bL d_s d_bz";
export var menuDivided = "w_pS d_fL d_fK d_bw d_ds d_bL d_s d_bz";
export var navbarItem = "w_pT d_bt";
export var navbarLogoItemWrapper = "w_fV d_fV d_by d_bL";
export var burgerToggle = "w_sR d_f8 d_bv d_bp d_W";
export var burgerToggleOpen = "w_sS d_f8 d_bv d_bp d_0";
export var burgerInput = "w_sT d_fZ d_t d_bv d_X d_b8 d_dh d_bq";
export var burgerLine = "w_sV d_fY d_bv d_W d_t d_bj d_cl";
export var burgerMenuLeft = "w_sW d_f3 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuRight = "w_sX d_f4 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuCenter = "w_sY d_f5 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuDivided = "w_sZ d_f3 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var btnWrapper = "w_dZ d_cT d_cM d_bw d_bD d_s";
export var cancelBtn = "w_s0 d_bY d_cz d_dx";
export var icon = "w_qJ";
export var secondary = "w_s1 d_by d_bL";