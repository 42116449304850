// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "r_rf d_bz";
export var datasheetRowWrapper = "r_lx d_lx d_bG";
export var datasheetLeftWrapper = "r_rg d_bw d_bL";
export var datasheetWrapperFull = "r_rh d_cz";
export var datasheetWrapperFullLeft = "r_nK d_cz d_bw d_bL d_bz d_bQ";
export var contentWrapper = "r_mC d_ly";
export var contentWrapperCenter = "r_rj d_lD";
export var datasheetLeftWrapperCenter = "r_rk d_lB d_bw d_bz d_bL d_bF";
export var datasheetImageCenterWrapper = "r_lv d_lv d_s d_cr";
export var datasheetRightWrapperCenter = "r_rl d_lz";
export var datasheetFooter = "r_lC d_lC";
export var alignLeft = "r_qP d_dr";
export var alignCenter = "r_bL d_ds";
export var alignRight = "r_qQ d_dt";
export var datasheetInnerWrapperNewLeft = "r_rm d_fb d_L d_W d_bw d_bL d_df";
export var datasheetInnerWrapperLeft = "r_rn d_d9 d_L d_W d_bw d_bL d_b2 d_df d_c6";
export var datasheetInnerWrapperRight = "r_rp d_fb d_L d_W d_bw d_bL d_df";
export var datasheetRowWrapperReverse = "r_rq d_bG";
export var datasheetSubtitle = "r_rr d_ct";
export var tableRow = "r_rs";
export var cell = "r_rt";
export var tableRowWrapper = "r_rv d_s d_dr";
export var tableHeadingCell = "r_rw";
export var tableHeading = "r_rx";
export var tableRowStriped = "r_ry";
export var tableRowHeading = "r_rz";
export var dataTable = "r_rB d_s";
export var imageWrapper = "r_qT d_fc d_W";
export var imageWrapperFull = "r_rC d_D d_s d_bb d_W";
export var imageWrapperIcon = "r_rD";
export var titleMargin = "r_rF d_cp";
export var datasheetInnerInnerWrapper = "r_rG d_s";
export var hasLabels = "r_rH";
export var label = "r_rJ";
export var SmallSmall = "r_rK z_rK z_s6 z_tt";
export var SmallNormal = "r_rL z_rL z_s6 z_tv";
export var SmallLarge = "r_rM z_rM z_s6 z_tr";