// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_st d_gs d_cp";
export var heroHeaderCenter = "v_gt d_gt d_cp d_ds";
export var heroHeaderRight = "v_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "v_sv d_gp d_cs";
export var heroParagraphCenter = "v_gq d_gq d_cs d_ds";
export var heroParagraphRight = "v_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "v_sw d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "v_sx d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "v_sy d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "v_sz d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "v_sB d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "v_sC z_sC";
export var heroExceptionNormal = "v_sD z_sD";
export var heroExceptionLarge = "v_sF z_sF";
export var Title1Small = "v_sG z_sG z_s6 z_s7";
export var Title1Normal = "v_sH z_sH z_s6 z_s8";
export var Title1Large = "v_sJ z_sJ z_s6 z_s9";
export var BodySmall = "v_sK z_sK z_s6 z_tq";
export var BodyNormal = "v_sL z_sL z_s6 z_tr";
export var BodyLarge = "v_sM z_sM z_s6 z_ts";