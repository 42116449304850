// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_rZ d_fl d_bC d_dr";
export var alignLeft = "t_qP d_fl d_bC d_dr";
export var alignDiscCenter = "t_r0 d_fm d_bz d_ds";
export var alignCenter = "t_bL d_fm d_bz d_ds";
export var alignDiscRight = "t_r1 d_fn d_bD d_dt";
export var alignRight = "t_qQ d_fn d_bD d_dt";
export var footerContainer = "t_r2 d_dS d_bS";
export var footerContainerFull = "t_r3 d_dQ d_bS";
export var footerHeader = "t_kc d_kc";
export var footerTextWrapper = "t_r4 d_s";
export var footerDisclaimerWrapper = "t_kk d_kk d_cg";
export var badgeWrapper = "t_r5 d_s d_bw d_bL d_bB d_bF";
export var footerDisclaimerRight = "t_kl d_kl d_bw";
export var footerDisclaimerLeft = "t_km d_km d_bw";
export var verticalTop = "t_r6 d_bw d_bF d_bK d_bH";
export var firstWide = "t_r7";
export var disclaimer = "t_r8";
export var socialDisclaimer = "t_r9";
export var left = "t_sb";
export var wide = "t_sc d_cv";
export var right = "t_sd d_bG";
export var line = "t_fg d_fh d_cr";
export var badgeDisclaimer = "t_sf d_by d_bL d_bF";
export var badgeContainer = "t_sg d_bw d_bD d_bL";
export var badge = "t_sh";
export var padding = "t_sj d_c4";
export var end = "t_sk d_bD";
export var linkWrapper = "t_sl d_dx";
export var link = "t_mF d_dx";
export var colWrapper = "t_sm d_ct";
export var media = "t_sn d_bt d_dv";
export var itemRight = "t_sp";
export var itemLeft = "t_sq";
export var itemCenter = "t_sr";
export var exceptionWeight = "t_ss z_ty";